import { Injectable } from '@angular/core';

import 'rxjs/add/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { CookieService } from 'angular2-cookie/services/cookies.service';

import { Md5 } from 'ts-md5/dist/md5';

@Injectable()
export class ImagesService {

  private URL = environment.urlbase + 'upload';

  private userEmail: string;
  private userPassword: string;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  private getHeader() {
    let md5 = new Md5();
    md5.appendStr(this.cookieService.get('password'));
    this.userEmail = this.cookieService.get('email');
    this.userPassword = md5.end() as string;
    return new HttpHeaders({
      "Authorization": "Basic " + btoa(this.userEmail+":"+this.userPassword)
    });
  } 

  upload(model): any {
    let formData: FormData = new FormData();
    formData.append('image', model, model.name);
    return this.http.post(this.URL, formData, { headers: this.getHeader() });
  }

}
