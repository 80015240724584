import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/of';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CookieService } from 'angular2-cookie/services/cookies.service';

import { Md5 } from 'ts-md5/dist/md5';

import { ContestStage } from '../models/contest-stage';
import { Screenwriting } from '../models/screenwriting';
import { ContestStageApproval } from '../models/contest-stage-approval';

@Injectable()
export class ContestStagesService {

  private URL = environment.urlbase + 'contest_stages';

  private userEmail: string;
  private userPassword: string;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  private getHeader() {
    let md5 = new Md5();
    md5.appendStr(this.cookieService.get('password'));
    this.userEmail = this.cookieService.get('email');
    this.userPassword = md5.end() as string;
    return new HttpHeaders({
      "Authorization": "Basic " + btoa(this.userEmail+":"+this.userPassword)
    });
  }

  all(): Observable<ContestStage[]> {
    return this.http.get<ContestStage[]>(this.URL, { headers: this.getHeader() });
  }

  show(id: number): Observable<{stage: ContestStage, screenwritings: Screenwriting[], approvals: ContestStageApproval[]}> {
    return this.http.get<{stage: ContestStage, screenwritings: Screenwriting[], approvals: ContestStageApproval[]}>
      (this.URL + '/' + id, { headers: this.getHeader() });
  }

  update(model: ContestStage): Observable<ContestStage> {
    return this.http.put<ContestStage>(this.URL + '/' + model.id, model, { headers: this.getHeader() });
  }

  store(model: ContestStage): Observable<ContestStage> {
    return this.http.post<ContestStage>(this.URL, model, { headers: this.getHeader() });
  }

  delete(model: ContestStage): Observable<any> {
    return this.http.delete<any>(this.URL + '/' + model.id, { headers: this.getHeader() });
  }

}
