import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { environment } from '../../../environments/environment';

import { CookieService } from 'angular2-cookie/services/cookies.service';

import { Md5 } from 'ts-md5/dist/md5';

import 'rxjs/add/observable/of';

@Injectable()
export class MeetingsService {

  private URL = environment.urlbase + 'meetings';
  private madURL = environment.urlbase + 'meeting_available_dates';

  private userEmail: string;
  private userPassword: string;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.userEmail = this.cookieService.get('email');
  }

  private getHeader(): HttpHeaders {
    this.userEmail = this.cookieService.get('email');
    if (this.cookieService.get('password') !== undefined) {
      let md5 = new Md5();
      md5.appendStr(this.cookieService.get('password'));
      this.userPassword = md5 !== null && md5 !== undefined ? (md5.end() as string) : '';
    } else this.userPassword = '';
    return new HttpHeaders({
      "Authorization": "Basic " + btoa(this.userEmail+":"+this.userPassword)
    });
  }

  all(email: string = '') {
    return this.http.get<any>(this.URL, {headers: this.getHeader(), params: { email: encodeURIComponent(email) }});
  }

  update(model: any) {
    return this.http.put(this.URL + '/' + model.id, model, { headers: this.getHeader() });
  }

  store(model: any) {
    return this.http.post(this.URL, model, { headers: this.getHeader() });
  }

  delete(model: any) {
    return this.http.delete(this.URL + '/' + model.id, { headers: this.getHeader() });
  }

  getMeetingAvailableDates(): Observable<any[]> {
    return this.http.get<any[]>(this.madURL, {headers: this.getHeader()});
  }

}
