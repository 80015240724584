import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AdminGuard } from './admin.guard';
import { PlayerGuard } from './player.guard';
import { UserGuard } from './user.guard';

import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';


const routes: Routes = [
  { path: 'login', loadChildren: 'app/auth/auth.module#AuthModule' },
  { path: 'admin', loadChildren: 'app/admin/admin.module#AdminModule', canActivate: [AdminGuard] },
  { path: 'player', loadChildren: 'app/player/player.module#PlayerModule', canActivate: [PlayerGuard] },
  { path: 'roteirista', loadChildren: 'app/user/user.module#UserModule', canActivate: [UserGuard] },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
