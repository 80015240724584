import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Package } from '../models/package';
import { Inscription } from '../models/inscription';

@Injectable()
export class RegistrationsService {

  private URL = environment.urlbase + 'inscriptions';

  private userEmail: string;
  private userPassword: string;

  constructor(
    private http: HttpClient
  ) { }

  private getHeader() {
    this.userEmail = 'frapa@frapa.art.br';
    this.userPassword = 'farrapos2014';
    return new HttpHeaders({
      "Authorization": "Basic " + btoa(this.userEmail+":"+this.userPassword)
    });
  } 

  all(): Observable<Package[]> {
    return this.http.get<any>(this.URL, {headers: this.getHeader()});
  }

  getUserFromEmail(email: string) {
    console.log(encodeURIComponent(email));
    return this.http.get<any>(this.URL + '/user', {headers: this.getHeader(), params: { email: encodeURIComponent(email) }});
  }

  getData() {
    return this.http.get<any>(this.URL + '/data', {headers: this.getHeader()});
  }

  store(model: Inscription) {
    return this.http.post(this.URL, model, { headers: this.getHeader() });
  }

  check(model: number): any {
    return this.http.get(this.URL + '/' + model, {headers: this.getHeader()});
  }

  delete(model: Inscription) {
    return this.http.delete(this.URL + '/' + model.id, { headers: this.getHeader()});
  }

}
