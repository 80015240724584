import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StateService } from './state.service';

import { SmartTableService } from './smart-table.service';
import { MapService } from './map.service';
import { UsersService } from './users.service';
import { SellsService } from './sells.service';
import { LoginAdminService } from './login.admin.service';
import { CookieService, CookieOptions } from 'angular2-cookie/core';
import { LayoutService } from './layout.service';
import { SmartTableFilterService } from './smart-table-filter.service';
import { StorageService } from './storage.service';
import { ImagesService } from './images.service';
import { PartnersService } from './partners.service';
import { SimpleService } from './simple.service';
import { InscriptionsService } from './inscriptions.service';
import { ProjectsService } from './projects.service';
import { ScreenwritingService } from './screenwriting.service';
import { MoviesService } from './movies.service';
import { ClippingService } from './clipping.service';
import { SpeakersService } from './speakers.service';
import { EventsService } from './events.service';
import { PlacesService } from './places.service';
import { PagesService } from './pages.service';
import { PackagesService } from './packages.service';
import { PriceTagsService } from './price.tags.service';
import { RegistrationsService } from './registrations.service';
import { PitchingService } from './pitching.service';
import { ContestStagesService } from './contest-stages.service';
import { ContestGroupsService } from './contest-groups.service';
import { ContestCriteriaService } from './contest-criteria.service';
import { ContestEvaluationsService } from './contest-evaluations.service';
import { ContestStageApprovalsService } from './contest-stage-approvals.service';
import { MeetingsService } from './meetings.service';

export function cookieServiceFactory() {
  return new CookieService();
}

const SERVICES = [
  StateService,
  SmartTableService,
  MapService,
  UsersService,
  InscriptionsService,
  PartnersService,
  SellsService,
  ProjectsService,
  ScreenwritingService,
  LoginAdminService,
  LayoutService,
  SmartTableFilterService,
  StorageService,
  ImagesService,
  SimpleService,
  MoviesService,
  ClippingService,
  SpeakersService,
  EventsService,
  PlacesService,
  PagesService,
  PackagesService,
  PriceTagsService,
  RegistrationsService,
  PitchingService,
  ContestStagesService,
  ContestGroupsService,
  ContestCriteriaService,
  ContestEvaluationsService,
  ContestStageApprovalsService,
  MeetingsService,
  { provide: CookieService, useFactory: cookieServiceFactory }
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
