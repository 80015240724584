import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CookieService } from 'angular2-cookie/services/cookies.service';

import { Md5 } from 'ts-md5/dist/md5';

import { ContestStage } from '../models/contest-stage';
import { ContestEvaluation } from '../models/contest-evaluation';

@Injectable()
export class ContestEvaluationsService {

  private URL = environment.urlbase + 'contest_evaluations';

  private userEmail: string;
  private userPassword: string;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  private getHeader() {
    let md5 = new Md5();
    md5.appendStr(this.cookieService.get('password'));
    this.userEmail = this.cookieService.get('email');
    this.userPassword = md5.end() as string;
    return new HttpHeaders({
      "Authorization": "Basic " + btoa(this.userEmail+":"+this.userPassword)
    });
  }

  bulkStore(model: ContestStage[]): Observable<ContestStage[]> {
    return this.http.post<ContestStage[]>(this.URL + '/bulk_store', model, { headers: this.getHeader() });
  }

  results(stage_id: number): Observable<ContestEvaluation[]> {
    return this.http.get<ContestStage[]>(this.URL + '/' + stage_id + '/results', { headers: this.getHeader() });
  }
}
