import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';
import { CookieService } from 'angular2-cookie/services/cookies.service';

import { Md5 } from 'ts-md5/dist/md5';
import { UserSchedule } from '../models/user.schedule';

@Injectable()
export class UsersService {

  private URL = environment.urlbase + 'users';

  private userEmail: string;
  private userPassword: string;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  private getHeader(): HttpHeaders {
    this.userEmail = this.cookieService.get('email');
    if (this.cookieService.get('password') !== undefined) {
      let md5 = new Md5();
      md5.appendStr(this.cookieService.get('password'));
      this.userPassword = md5 !== null && md5 !== undefined ? (md5.end() as string) : '';
    } else this.userPassword = '';
    return new HttpHeaders({
      "Authorization": "Basic " + btoa(this.userEmail+":"+this.userPassword)
    });
  }

  getUser(): Observable<any> {
    var users = {
      currentUser: {
        name: this.cookieService.get('name') ? this.cookieService.get('name') : null,
        picture: this.cookieService.get('picture') ? this.cookieService.get('picture') : null
      }
    }
    return Observable.of(users);
  }

  all(): Observable<User[]> {
    return this.http.get<any>(this.URL, { headers: this.getHeader() });
  }

  allOf(role: string): Observable<User[]> {
    return this.http.get<any>(this.URL, { headers: this.getHeader(), params: { role: role } });
  }

  getFromEmail(email: string): Observable<User> {
    return this.http.get<any>(this.URL, { headers: this.getHeader(), params: { email: encodeURIComponent(email) } });
  }

  show(id: number): Observable<User> {
    return this.http.get<any>(this.URL + '/' + id, { headers: this.getHeader() });
  }

  update(model: User): Observable<User> {
    return this.http.put<User>(this.URL + '/' + model.id, model, { headers: this.getHeader() });
  }

  store(model: User): Observable<User> {
    return this.http.post<User>(this.URL, model, { headers: this.getHeader() });
  }

  delete(model: User) {
    return this.http.delete(this.URL + '/' + model.id, { headers: this.getHeader() });
  }

  getMySchedule(model: UserSchedule | number) {
    return this.http.get<User>(this.URL + '/' + (typeof(model) == 'number' ? model : model.user_id) + '/schedule', { headers: this.getHeader() });
  }

  storeMySchedule(model: UserSchedule) {
    return this.http.post(this.URL + '/' + model.user_id + '/schedule', model, { headers: this.getHeader() });
  }

  updateMySchedule(model: UserSchedule) {
    return this.http.put(this.URL + '/' + model.user_id + '/schedule/' + model.id, model, { headers: this.getHeader() });
  }

  deleteMySchedule(model: UserSchedule) {
    return this.http.delete(this.URL + '/' + model.user_id + '/schedule/' + model.id, { headers: this.getHeader() });
  }

  scheduleBulkStore(model: any[]): Observable<any[]> {
    return this.http.post<any[]>(this.URL + '/schedule/bulk_store', model, { headers: this.getHeader() });
  }

  updatePlayerSettings(model: User): Observable<any> {
    return this.http.post<any>(this.URL + '/players/settings', model, { headers: this.getHeader() });
  }

}
