import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { CookieService } from 'angular2-cookie/services/cookies.service';
import { environment } from '../../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { User } from '../models/user';

@Injectable()
export class LoginAdminService {

  private URL = environment.loginbase + 'login';
  private logged = false;

  private userEmail: string;
  private userPassword: string;
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
  ) { }

  checkAdmin(): Promise<boolean> {
    return new Promise<boolean>(
      logged => {
        logged(true);
        const email = this.cookieService.get('email');
        const password = this.cookieService.get('password');
        const role = this.cookieService.get('role');
        if (role !== 'Admin') {
          logged(false);
        } else if (this.logged === true) {
          logged(true);
        } else if (email !== undefined && password !== undefined) {
          this.login(email, password)
            .then(valid => {
              logged(valid);
            },
          );
        } else {
          logged(false);
        }
      },
    );
  }

  checkPlayer(): Promise<boolean> {
    return new Promise<boolean>(
      logged => {
        logged(true);
        const email = this.cookieService.get('email');
        const password = this.cookieService.get('password');
        const role = this.cookieService.get('role');
        if (role !== 'Player') {
          logged(false);
        } else if (this.logged === true) {
          logged(true);
        } else if (email !== undefined && password !== undefined) {
          this.login(email, password)
            .then(valid => {
              logged(valid);
            },
          );
        } else {
          logged(false);
        }
      },
    );
  }

  checkScreenwriter(): Promise<boolean> {
    return new Promise<boolean>(
      logged => {
        logged(true);
        const email = this.cookieService.get('email');
        const password = this.cookieService.get('password');
        const role = this.cookieService.get('role');
        if (role !== 'Screenwriter') {
          logged(false);
        } else if (this.logged === true) {
          logged(true);
        } else if (email !== undefined && password !== undefined) {
          this.login(email, password)
            .then(valid => {
              logged(valid);
            },
          );
        } else {
          logged(false);
        }
      },
    );
  }

  login(email: string, password: string): Promise<boolean> {
    let md5: Md5 = new Md5();
    md5.appendStr(password);
    this.userEmail = email;
    this.userPassword = md5.end() as string;
    this.headers = new HttpHeaders({
      "Authorization": "Basic " + btoa(this.userEmail+":"+this.userPassword),
      "content-type": "application/json"
    });
    console.log(this.headers);
    return new Promise<boolean>(
      logged => { 
        this.http.post<User>(this.URL, { email: this.userEmail, password: this.userPassword })
          .subscribe(
            (done: User): void => {
              this.logged = true;
              this.cookieService.put('id', done.id.toString());
              this.cookieService.put('email', email);
              this.cookieService.put('password', password);
              this.cookieService.put('name', done.name);
              this.cookieService.put('picture', done.image);
              this.cookieService.put('role', (done.role == 1 ? 'Admin' : (done.role == 2 ? 'Player' : 'Screenwriter')));
              this.cookieService.put('agenda', done.show_schedule ? 'sim' : '');
              this.cookieService.put('chose', done.choose_projects ? 'sim' : '');
              logged(true);
            },
            (error: HttpErrorResponse): void => {
              console.log(error);
              this.logged = false;
              this.cookieService.remove('id');
              this.cookieService.remove('email');
              this.cookieService.remove('password');
              this.cookieService.remove('name');
              this.cookieService.remove('picture');
              this.cookieService.remove('role');
              this.cookieService.remove('agenda');
              this.cookieService.remove('chose');
              logged(false);
            },
        );
      },
    );
  }

  logout() {
    this.logged = false;
    this.cookieService.remove('id');
    this.cookieService.remove('email');
    this.cookieService.remove('password');
    this.cookieService.remove('name');
    this.cookieService.remove('picture');
    this.cookieService.remove('role');
    this.cookieService.remove('agenda');
    this.cookieService.remove('chose');
  }

}
