import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UsersService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { LayoutService } from '../../../@core/data/layout.service';

import { User } from '../../../@core/models/user';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: User;

  userMenu;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private usersService: UsersService,
    private analyticsService: AnalyticsService,
    private layoutService: LayoutService
  ) {
    this.userMenu = [
      { title: 'Sair', link: 'logout' }
    ];
   }

  ngOnInit() {
    this.usersService.getUser()
      .subscribe((users: any) => this.user = users.currentUser);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}
