import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { HttpClient } from '@angular/common/http';
import { MapResult } from '../models/map.result';

@Injectable()
export class MapService {

  private googleMapsAPI = 'https://maps.google.com/maps/api/';
  private outputAndKey = '/json?key=AIzaSyDdyQdydlMR-O0jfYfjnRYXoNZzdhNfNa0&';
  private URL = this.googleMapsAPI + 'geocode' + this.outputAndKey;
  private timezone = 'https://maps.googleapis.com/maps/api/timezone' + this.outputAndKey;

  constructor(private http: HttpClient) {}

  getCoords(address): Observable<MapResult> {
    return this.http.get<any>(this.URL + 'address=' + address);
  }

  getAddress(lat, lng): Observable<MapResult> {
    return this.http.get<any>(this.URL + 'latlng=' + lat + ',' + lng);
  }

  getTimeZone(lat, lng): Observable<MapResult> {
    const timestamp = Math.floor(Date.now() / 1000);
    return this.http.get<any>(this.timezone + 'location=' + lat + ',' + lng + '&timestamp=' + timestamp);
  }

}
