import { Injectable } from '@angular/core';
import 'rxjs/add/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Project } from '../models/project';
import { CookieService } from 'angular2-cookie/services/cookies.service';

import { Md5 } from 'ts-md5/dist/md5';

@Injectable()
export class ProjectsService {

  private URL = environment.urlbase + 'projects';

  private userEmail: string;
  private userPassword: string;

  private testEmail: string = 'susankalik@gmail.com';

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.userEmail = this.cookieService.get('email');
  }

  private getHeader() {
    this.userEmail = this.cookieService.get('email');
    if (this.cookieService.get('password') !== undefined) {
      let md5 = new Md5();
      md5.appendStr(this.cookieService.get('password'));
      this.userPassword = md5 !== null && md5 !== undefined ? (md5.end() as string) : '';
    } else this.userPassword = '';
    return new HttpHeaders({
      "Authorization": "Basic " + btoa(this.userEmail+":"+this.userPassword)
    });
  }

  info() {
    return this.http.get<any>(this.URL + '/info', {headers: this.getHeader()});
  }

  all(page: number = 1) {
    return this.http.get<any>(this.URL + '?page=' + page, {headers: this.getHeader()});
  }

  matchInfo() {
    this.userEmail = this.cookieService.get('email');
    const userEmail: string = this.userEmail == 'suporte@aioria.com.br' || this.userEmail ==  'andre@aioria.com.br' ? this.testEmail: this.userEmail;
    return this.http.get<any>(this.URL, {headers: this.getHeader(), params: { email: encodeURIComponent(userEmail), info: '1' }});
  }
 
  match(page: number = 1) {
    this.userEmail = this.cookieService.get('email');
    const userEmail: string = this.userEmail == 'suporte@aioria.com.br' || this.userEmail ==  'andre@aioria.com.br' ? this.testEmail: this.userEmail;
    return this.http.get<any>(this.URL, {headers: this.getHeader(), params: { email: encodeURIComponent(userEmail), page: page.toString() }});
  }

  my() {
    this.userEmail = this.cookieService.get('email');
    const userEmail: string = this.userEmail == 'suporte@aioria.com.br' || this.userEmail ==  'andre@aioria.com.br' ? this.testEmail: this.userEmail;
    return this.http.get<any>(this.URL + '/mine', {headers: this.getHeader(), params: { email: encodeURIComponent(userEmail) }});
  }

  withMeetings() {
    return this.http.get<any>(this.URL + '/meetings', {headers: this.getHeader()});
  }

  schedule() {
    this.userEmail = this.cookieService.get('email');
    return this.http.get<any>(this.URL + '/schedule', {headers: this.getHeader(), params: { email: encodeURIComponent(this.userEmail) }});
  }

  read(project_id: any) {
    this.userEmail = this.cookieService.get('email');
    return this.http.get<any>(this.URL + '/read/' + project_id + '/' + this.userEmail, {headers: this.getHeader()});
  }

  approve(project_id: any) {
    this.userEmail = this.cookieService.get('email');
    return this.http.get<any>(this.URL + '/approve/' + project_id + '/' + this.userEmail, {headers: this.getHeader()});
  }

  reject(project_id: any) {
    this.userEmail = this.cookieService.get('email');
    return this.http.get<any>(this.URL + '/reject/' + project_id + '/' + this.userEmail, {headers: this.getHeader()});
  }

  unread(project_id: any) {
    this.userEmail = this.cookieService.get('email');
    return this.http.get<any>(this.URL + '/unread/' + project_id + '/' + this.userEmail, {headers: this.getHeader()});
  }

  update(model: Project) {
    return this.http.put(this.URL + '/' + model.id, model, { headers: this.getHeader() });
  }

  store(model: Project) {
    return this.http.post(this.URL, model, { headers: this.getHeader() });
  }

  delete(model: Project) {
    return this.http.delete(this.URL + '/' + model.id, { headers: this.getHeader() });
  }

  playerLikes(email: string) {
    return this.http.get<any>(this.URL + '/approved/' + email, {headers: this.getHeader()});
  }

}
