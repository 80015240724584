import { Injectable } from '@angular/core';

@Injectable()
export class SmartTableService {

  data = [{
    id: 1,
    contractor: 'Steven C. Soule',
    startDate: '02/03',
    endDate: '03/05',
    price: 5000,
    notes: null,
    button: 'select',
  }, {
    id: 2,
    contractor: 'Joseph N. Gorski',
    startDate: '02/04',
    endDate: '03/05',
    price: 3000,
    notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    button: 'select',
  }, {
    id: 3,
    contractor: 'Esperanza P. Wallace',
    startDate: '02/08',
    endDate: '03/03',
    price: 2500,
    notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et ' +
    'dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ' +
    'ex ea commodo consequat.',
    button: 'select',
  }, {
    id: 4,
    contractor: 'Shawn W. Henry',
    startDate: '02/08',
    endDate: '03/06',
    price: 1500,
    notes: null,
    button: 'select',
  }];

  getData() {
    return this.data;
  }
}
