import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pitching } from '../models/pitching';
import { environment } from '../../../environments/environment';
import { CookieService } from 'angular2-cookie/services/cookies.service';

import { Md5 } from 'ts-md5/dist/md5';

@Injectable()
export class PitchingService {

  private URL: string = environment.urlbase + 'pitchings';

  private userEmail: string;
  private userPassword: string;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  private getHeader() {
    let md5: Md5 = new Md5();
    md5.appendStr(this.cookieService.get('password'));
    this.userEmail = this.cookieService.get('email');
    this.userPassword = md5.end() as string;
    return new HttpHeaders({
      "Authorization": "Basic " + btoa(this.userEmail+":"+this.userPassword)
    });
  }

  all(): Observable<any> {
    return this.http.get<any>(this.URL, { headers: this.getHeader() });
  }

  show(id: number): Observable<any> {
    return this.http.get<any>(this.URL + '/' + id, { headers: this.getHeader() });
  }

  update(model: Pitching) {
    return this.http.put(this.URL + '/' + model.id, model, { headers: this.getHeader() });
  }

  store(model: Pitching) {
    return this.http.post(this.URL, model, { headers: this.getHeader() });
  }

  delete(model: Pitching) {
    return this.http.delete(this.URL + '/' + model.id, { headers: this.getHeader() });
  }

}
