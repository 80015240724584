import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { safeHTML } from "./safe.html";

@NgModule({
  declarations:[safeHTML],
  imports:[CommonModule],
  exports:[safeHTML]
})

export class MainPipeModule {}